.invitationButton{
    display: flex;
    align-items: center;
    gap:5px;
    font-size: 14px;
    font-weight: 400;
}
.ant-btn-default.ant-btn-background-ghost{
    color: #00AC1E;
    border-color: #00AC1E;
}