.profile-descriptions-header {
  display: flex;
  align-items: center;
  height: 36px;
  margin-bottom: 20px;
}

.profile-descriptions-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: auto;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}

.profile-title-main {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 0;
}
